/*
export const _GLOBAL = {
  URLBACKEND: 'http://localhost:11340',
  PORTALURL: 'http://localhost:8080'
}
*/

export const _GLOBAL = {
  URLBACKEND: 'https://apieoffice.kskgroup.co.id',
  PORTALURL: 'https://eoffice.kskgroup.co.id'
}
